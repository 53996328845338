.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
    font-size: 1.5rem !important;
    color: #000 !important;
    padding-bottom: 1rem !important;
  }

.swal2-input {
  font-size: 0.85rem !important;
  border-radius: 4px !important;
}

.swal2-input:focus {
  border: 1px solid #E4782A !important;
  caret-color: #E4782A !important;
  box-shadow: none !important;
}

.swal2-input::placeholder {
  font-size: 1rem !important;
}

.swal2-validation-message {
  border-radius: 8px !important;
  font-size: 0.8rem !important;
  padding: 0.25rem !important;
  margin: 1em 2.65em 0 !important;
}

.swal2-styled {
  padding: 1rem 1.5rem !important;
  font-size: 1rem !important;
}

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-styled:hover  {
  background-color: #fff !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  min-width: 8rem !important;
  border-radius: 20px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #E4782A !important;
  color: #fff !important;
}

.swal2-styled.swal2-cancel {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid rgba(228, 120, 42, 0.3) !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}