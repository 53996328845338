@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: 'Noto Sans KR', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
